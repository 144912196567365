import { SuggestionData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import params from '../../../core/sysext/Boutique/containers/Suggestion/params.json';
import CMSView from '../../../core/sysext/CMS/components/View';
import Backoffice from '../../../core/sysext/CMS/containers/Backoffice';
import Form from '../../../core/sysext/CMS/containers/Boutique/components/new/Form';

const PageSuggestionsCreate: FC<PageProps> = props => {
  const { location } = props;

  const boutique = useSelector((state: any) =>
    state.cms.boutiques
      ? state.cms.boutiques[state.cms.indexBoutique || 0]
      : undefined,
  );

  const model = new SuggestionData({
    boutique,
    params,
    orderDirection: 'asc',
    orderField: 'libelle',
  });

  return (
    <Backoffice location={location}>
      <CMSView>
        <Form
          {...props}
          itemPathnamePrefix="/boutique/suggestions/"
          model={model}
          name="suggestion"
          type="create"
        />
      </CMSView>
    </Backoffice>
  );
};

export default PageSuggestionsCreate;
